.our-story::before {
    @apply hidden sm:block;
    content: "";
    position: absolute;
    width: 100vw;
    height: 110%;
    left: 50%;
    transform: translateX(-50%);
    top: -5%;
    /*margin-bottom: 80px;*/
    background-position: center;
    background-size: cover;
    background-image: url("./back.jpeg");
    z-index: -1;
}

/*.our-story::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 100%;*/
/*    width: 100vw;*/
/*    height: 100%;*/
/*    background-color: #ffffff;*/
/*    border-left: 1px solid #00000011;*/
/*}*/
