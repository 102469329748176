.header {
    background-color: #ffffff;
}

.header .title .large {
    font-size: 64px;
    line-height: 64px;
    color: #28353A;
    margin: 0;
}

.header .title .small {
    font-size: 36px;
    line-height: 36px;
    margin: 0;
}

.header .title img {
    width: auto;
}
