.about-us {
    position: relative;
}

/*.about-us::before {*/
/*    content: "";*/
/*    background-color: #28353a;*/
/*    width: 200vw;*/
/*    transform: translateX(-25%);*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: -1;*/
/*}*/

.about-us .right .sections {
    text-align: center;
}

.about-us .right .image {
    background-size: cover;
    background-position: center;
}

.about-us .right .image .dude {
    transform: translateY(-50%);
    filter: drop-shadow(2px 2px 1px #000)
}

.about-us .right .sections .box-container.left-box {
    padding: 4px 4px 4px 0px;
}

.about-us .right .sections .box-container.right-box {
    padding: 4px 0px 4px 4px;
}

.shadow {
    box-shadow: 0 4px 4px #00000044;
    /*animation: rotate 5s linear infinite;*/
}

.about-us .right .sections img {
    height: 150px;
    /*height: auto;*/
}

.about-us .right .sections p {
    margin: 0;
}

@keyframes rotate {
    0% {
        transform: rotate3d(1, 1, 0, 10deg);
    }
    10% {
        transform: rotate3d(1, 0, 0, 10deg);
    }
    20% {
        transform: rotate3d(1, -1, 0, 10deg);
    }
    30% {
        transform: rotate3d(0, -1, 0, 10deg);
    }
    40% {
        transform: rotate3d(-1, -1, 0, 10deg);
    }
    50% {
        transform: rotate3d(-1, 0, 0, 10deg);
    }
    60% {
        transform: rotate3d(-1, 1, 0, 10deg);
    }
    70% {
        transform: rotate3d(0, 1, 0, 10deg);
    }
    100% {
        transform: rotate3d(1, 1, 0, 10deg);
    }
}
