body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.App {
  max-width: 1200px;
  /*display: flex;*/
  margin: 0 auto;
}

/*@media screen(min-width: 1024px) {*/
/*  .App {*/
/*    width: 1024px;*/
/*  }*/
/*}*/

.full-stripe {
  position: relative;
}

.full-stripe::before {
  content: "";
  background-color: #28353a;
  width: 100vw;
  transform: translateX(-50%);
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
}
